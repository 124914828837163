import { apiUtils, endpoints } from 'api';

export const api = {
  login: async (data) => {
    return await apiUtils.post(endpoints.LOGIN, data, false);
  },
  googleLogin: async (data) => {
    return await apiUtils.post(endpoints.GOOGLE_LOGIN, data, false);
  },
  connectZoom: async (data) => {
    return await apiUtils.post(endpoints.CONNECT_ZOOM, data);
  },
  disconnectZoom: async (data) => {
    return await apiUtils.post(endpoints.DISCONNECT_ZOOM, data);
  },
  audioRetranscribe: async (id) => {
    return await apiUtils.patch(endpoints.AUDIO_RETRANSCRIBE(id), {});
  },
  audioTranscriptList: async () => {
    return await apiUtils.get(endpoints.AUDIO_TRANSCRIPTIONS);
  },
  audioTranscriptDetail: async (id) => {
    return await apiUtils.get(endpoints.AUDIO_TRANSCRIPT_DETAIL(id));
  },
  overallAudioTranscriptionState: async (id) => {
    return await apiUtils.get(endpoints.OVERALL_AUDIO_TRANSCRIPTION_STATE(id));
  },
  transcriptionStatus: async (id) => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_STATUS(id));
  },
  summaryStatus: async (id) => {
    return await apiUtils.get(endpoints.SUMMARY_STATUS(id));
  },
  audioTranscriptionUpdate: async ({id, data}) => {
    return await apiUtils.patch(endpoints.AUDIO_TRANSCRIPT_DETAIL(id), data);
  },
  audioTranscriptionDelete: async ({id}) => {
    return await apiUtils.delete(endpoints.AUDIO_TRANSCRIPT_DELETE(id));
  },
  transcriptionSpeakerUpdate: async ({id, data}) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_SPEAKER_DETAIL(id), data);
  },
  transcriptionSummaryCreate: async (id) => {
    return await apiUtils.patch(endpoints.TRANSCRIPTION_SUMMARY_DETAIL(id), {});
  },
  transcriptionSummaryList: async () => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_SUMMARIES);
  },
  transcriptionSummaryDetail: async (id) => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_SUMMARY_DETAIL(id));
  },
  transcriptionRemainingTime: async (id) => {
    return await apiUtils.get(endpoints.TRANSCRIPTION_REMAINING_TIME(id), {}, true, false);
  },
  summaryRemainingTime: async (id) => {
    return await apiUtils.get(endpoints.SUMMARY_REMAINING_TIME(id), {}, true, false);
  },
  disconnectGoogleMeet:  async (id) => {
    return await apiUtils.post(endpoints.DISCONNECT_GOOGLE_MEET, {});
  },
};
