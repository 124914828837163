import { errorsCodes } from 'common/errors';
import { toast } from 'react-toastify';
import jsPDF from "jspdf";
import {
  FILE_TYPES,
  LOGO_BASE64,
  STATE_FETCH_INTERVAL,
  VIDEO_CONVERSION_BUFFER,
  VIDEO_CONVERSION_TIME_PER_MB
} from "./constants";

export const utils = {
  getErrorString: (error) => {
    let textError = '';
    const arr = Object.values(error.response.data).flat();
    for (let i = 0; i < arr.length; i += 1) {
      textError += `${arr[i]}\n`;
    }
    return textError;
  },
  showErrorToast: (error) => {
    if (error.response?.status === errorsCodes.SERVER_ERROR) {
      toast.error(error.response.statusText);
    }

    if (error.response?.data) {
      toast.error(utils.getErrorString(error));
    } else {
      toast.error(error.message ? error.message : error);
    }
  },
  toArrayMapping: (data) => {
    return data.reduce((dict, item) => {
      dict[item.id] = item;
      return dict;
    }, {});
  },
  toArrayWithKey: (data) => {
    return data ? Object.entries(data)?.map(([key, value]) => ({
      ...value,
      key,
    })) : [];
  },
  removeItemsFromArray: (arr, target) => {
    return arr.filter((item) => item !== target);
  },
  timeStringToSeconds: (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':')?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  },
  secondsToTimeString: (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  },
  binarySearch: (segments, currentTime) => {
    let low = 0;
    let high = segments.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (segments[mid].end_time < currentTime) {
        low = mid + 1;
      } else if (segments[mid].start_time > currentTime) {
        high = mid - 1;
      } else {
        return mid;
      }
    }
    return low;
  },
  getNewSegmentIndex: (transcriptionSegments, currentTime) => {
    if (!transcriptionSegments || transcriptionSegments.length === 0) {
      return 0;
    }

    currentTime = Math.round(currentTime);

    let currentIndex = utils.binarySearch(transcriptionSegments, currentTime);
    currentIndex = currentIndex === -1 ? transcriptionSegments.length - 1 : currentIndex;
    return currentIndex
  },
  getRelevantSegments: (transcriptionSegments, currentTime) => {
    if (!transcriptionSegments || transcriptionSegments.length === 0) {
      return [[], 0];
    }

    let currentIndex = utils.getNewSegmentIndex(transcriptionSegments, currentTime);
    const start = Math.max(0, currentIndex - 3);
    const end = Math.min(transcriptionSegments.length, currentIndex + 3);
    return [transcriptionSegments.slice(start, end), currentIndex - start, currentIndex]
  },
  removeSegmentsByIndices: (segments, indices) => {
    return segments.filter((_, index) => !indices.includes(index));
  },
  groupConsecutiveIndices: (indices) => {
    const groups = [];
    let group = [indices[0]];

    for (let i = 1; i < indices.length; i++) {
      if (indices[i] === indices[i - 1] + 1) {
        group.push(indices[i]);
      } else {
        groups.push(group);
        group = [indices[i]];
      }
    }
    groups.push(group);
    return groups;
  },
  sortArray: (data) => {
    return data.sort((item1, item2) => item1 - item2);
  },
  mergeSegmentsByIndices: (segments, indices) => {
    const sortedIndices = utils.sortArray(indices);
    const groupedIndices = utils.groupConsecutiveIndices(sortedIndices);

    groupedIndices.forEach(group => {
      if (group.length < 2) return;

      const segmentsToMerge = group.map(index => segments[index]);
      const mergedText = segmentsToMerge.map(segment => segment.text).join(' ');
      const startTime = segmentsToMerge[0].start_time;
      const endTime = segmentsToMerge[segmentsToMerge.length - 1].end_time;

      // todo: assuming that speaker for segments is same
      segments[group[0]] = {
        text: mergedText,
        speaker: segmentsToMerge[0].speaker,
        start_time: startTime,
        end_time: endTime,
        key: `${utils.secondsToTimeString(startTime)} - ${utils.secondsToTimeString(endTime)}`
      };

      for (let i = group.length - 1; i > 0; i--) {
        segments.splice(group[i], 1);
      }
    });

    return segments
  },
  convertToSRT: (transcriptionSegments, speakerMap) => {
    return transcriptionSegments.map((segment, index) => {
      const startTime = utils.secondsToTimeString(segment.start_time);
      const endTime = utils.secondsToTimeString(segment.end_time);
      return `${index + 1}\n${startTime},000 --> ${endTime},000\n(${speakerMap[segment.speaker].name}) ${segment.text}\n`;
    }).join('\n');
  },
  downloadSRT: (filename, srtContent) => {
    const blob = new Blob([srtContent], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },
  handleTranscriptionExport: (transcriptionSegments, speakerMap) => {
    const srtContent = utils.convertToSRT(transcriptionSegments, speakerMap);
    utils.downloadSRT('transcription.srt', srtContent);
  },
  getPDF: (title, fileType, duration, speakers) => {
    const doc = new jsPDF();

    doc.setFontSize(12);

    doc.addImage(LOGO_BASE64, "JPEG", 26, 20, 24, 20);


    const startY = 55;
    // Set fonts and add text sections
    doc.setFont("helvetica", "normal", "bold");
    doc.text("Meeting Title:", 20, startY);

    doc.setFont("helvetica", "normal", "normal");
    doc.text(title || '', 50, startY);

    doc.setFont("helvetica", "normal", "bold");
    doc.text("Meeting Type:", 20, startY + 10);

    doc.setFont("helvetica", "normal", "normal");
    doc.text(FILE_TYPES[fileType] || '', 50, startY + 10);

    doc.setFont("helvetica", "normal", "bold");
    doc.text("Meeting Duration:", 20, startY + 20);

    doc.setFont("helvetica", "normal", "normal");
    doc.text(duration || '', 58, startY + 20);

    doc.setFont("helvetica", "normal", "bold");
    doc.text("Speakers:", 20, startY + 30);

    doc.setFont("helvetica", "normal", "normal");
    const speakerLines = doc.splitTextToSize((speakers?.map(speaker => speaker.name) || []).join(', '), 125);
    let y = startY + 30;
    speakerLines.forEach((line) => {
      if (y > 275) {
        doc.addPage();
        y = 25;
      }
      doc.text(line, 43, y);
      y += 5;
    });

    return [doc, y]
  },
  generateTranscriptionPDF: (transcriptionData) => {
    let transcription = transcriptionData?.transcription;
    let speakerMap = transcriptionData?.speakerMap;
    let transcriptionStr = '';
    for (let i = 0; i < transcriptionData?.transcription.length; i++) {
      transcriptionStr += `(${speakerMap[transcription[i].speaker].name}) ${transcription[i].text}\n\n`
    }
    let [doc, y] = utils.getPDF(
      transcriptionData?.title, transcriptionData?.file_type, transcriptionData?.audio_duration,
      transcriptionData?.speakers, transcriptionStr
    );

    y += 10;
    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(14)
    doc.text("Meeting Notes:", 20, y);

    doc.setFontSize(12)
    doc.setFont("helvetica", "normal", "normal");
    const lines = doc.splitTextToSize(transcriptionStr, 170);
    y += 10;

    lines.forEach((line) => {
      if (y > 275) {
        doc.addPage();
        y = 25;
      }
      doc.text(line, 20, y);
      y += 5;
    });


    doc.save('transcription.pdf');
  },
  generateSummaryPDF: (summaryData) => {
    let [doc, y] = utils.getPDF(
      summaryData?.title, summaryData?.file_type, summaryData?.audio_duration,
      summaryData?.speakers
    );

    const renderSummaryItemsToPDF = (summary) => {
      if (!summary) {
        return null;
      } else if (Array.isArray(summary)) {
        summary.map((item) => {
          y += 2;
          renderSummaryItemsToPDF(item)
        })
      } else if (typeof summary === 'object') {
        y += 10;
        Object.entries(summary).map(([key, value]) => {
          doc.setFont("helvetica", "normal", "bold");
          doc.text(`${utils.snakeToTitleCase(key)}:`, 20, y);
          renderSummaryItemsToPDF(value)
          y += 10;
        });
      } else {
        doc.setFont("helvetica", "normal", "normal");
        const lines = doc.splitTextToSize(summary, 170); // Adjust width as needed
        lines.forEach((line) => {
          if (y > 275) { // Check for page overflow
            doc.addPage();
            y = 25;
          }
          y += 6;
          doc.text(line, 20, y);
        });
      }
    }

    y += 10;

    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(14)
    doc.text("Summary:", 20, y);

    doc.setFontSize(12)
    renderSummaryItemsToPDF(utils.parseSummaryData(summaryData?.summary));
    doc.save('summary.pdf');
  },
  ellipseText: (text, noOfChar) => {
    return text.length > noOfChar ? `${text.substring(0, 11)}...` : text;
  },
  snakeToTitleCase: (snakeStr) => {
    return snakeStr
      .split('_') // Split the string by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  },
  mapProgress: (progress, min, max) => {
    const newProgress = Math.round(progress * (max - min) / 100 + min);
    return newProgress > max ? max : newProgress;
  },
  getEstimateConversionTime: (fileSize) => {
    return Math.round((fileSize / (1024 * 1024)) * VIDEO_CONVERSION_TIME_PER_MB) + VIDEO_CONVERSION_BUFFER; // fileSize in bytes to MB
  },
  isObjectEmpty: (obj) => {
    return obj === null || obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object);
  },
  getStatusFetchInterval: (estimatedTime) => {
    const fetchInterval = estimatedTime || 0 / 10;
    return Math.round(fetchInterval > STATE_FETCH_INTERVAL ? fetchInterval : STATE_FETCH_INTERVAL) * 1000;
  },
  parseSummaryData: (data) => {
    let parsedData;
    try {
      parsedData = typeof data === "string" ? JSON.parse(data) : data;
    } catch (e) {
      parsedData = {};
    }
    return parsedData;
  }
};
