import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import generalExtraActions from "../actions/general";
import { utils } from "../../common";

const INITIAL_STATE = {
  loading: false,
  error: null,
  transcriptionData: {},
  originalTranscriptionData: {},
  audioTranscriptions: [],
  transcriptionSummaries: [],
  summaryData: {}
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetGeneralSlice: () => INITIAL_STATE,
    resetData: (state, {payload}) => {
      state.transcriptionData = state.originalTranscriptionData = state.summaryData = {};
    },
    updateTranscriptionSegmentText: (state, {payload}) => {
      const {index, updatedText} = payload;
      const transcription = state.transcriptionData.transcription;
      transcription[index].text = updatedText;
      state.transcriptionData.transcription = transcription;
    },
    updateTranscriptionSegmentSpeaker: (state, {payload}) => {
      const {index, speaker} = payload;
      const transcription = state.transcriptionData.transcription;
      transcription[index].speaker = speaker;
      state.transcriptionData.transcription = transcription;
    },
    mergeTranscriptionSegments: (state, {payload}) => {
      const {indices} = payload;
      if (indices.length < 2) return;
      state.transcriptionData.transcription = utils.mergeSegmentsByIndices(
        state.transcriptionData.transcription, indices
      );
    },
    removeTranscriptionSegments: (state, {payload}) => {
      const {indices} = payload;
      state.transcriptionData.transcription = utils.removeSegmentsByIndices(
        state.transcriptionData.transcription, indices
      );
    },
    cancelTranscriptionChanges: (state) => {
      state.transcriptionData = state.originalTranscriptionData;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generalExtraActions.audioTranscriptList.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.error = null;
      state.audioTranscriptions = payload;
    });
    builder.addCase(generalExtraActions.transcriptionSummaryList.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.error = null;
      state.transcriptionSummaries = payload;
    });
    builder.addCase(generalExtraActions.deleteTranscription.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptDetail.fulfilled,
        generalExtraActions.audioTranscriptionUpdate.fulfilled,
        generalExtraActions.audioRetranscribe.fulfilled,
      ),
      (state, {payload}) => {
        state.loading = false;
        state.error = null;
        const transcriptionData = payload;
        transcriptionData.speakerMap = utils.toArrayMapping(payload.speakers);
        state.transcriptionData = state.originalTranscriptionData = transcriptionData;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.transcriptionSummaryCreate.fulfilled,
        generalExtraActions.transcriptionSummaryDetail.fulfilled,
      ),
      (state, {payload}) => {
        state.loading = false;
        state.error = null;
        state.summaryData = payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptList.pending,
        generalExtraActions.audioTranscriptDetail.pending,
        generalExtraActions.audioRetranscribe.pending,
        generalExtraActions.audioTranscriptionUpdate.pending,
        generalExtraActions.transcriptionSummaryList.pending,
        generalExtraActions.transcriptionSummaryDetail.pending,
        generalExtraActions.transcriptionSummaryCreate.pending,
        generalExtraActions.deleteTranscription.pending,
      ),
      (state, {payload}) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.audioTranscriptList.rejected,
        generalExtraActions.audioTranscriptDetail.rejected,
        generalExtraActions.audioRetranscribe.rejected,
        generalExtraActions.audioTranscriptionUpdate.rejected,
        generalExtraActions.transcriptionSummaryList.rejected,
        generalExtraActions.transcriptionSummaryDetail.rejected,
        generalExtraActions.transcriptionSummaryCreate.rejected,
        generalExtraActions.deleteTranscription.rejected
      ),
      (state, {payload}) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const {actions: generalActions, reducer} = authSlice;
export default reducer;
