import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { routes, utils } from "common";
import { Loader, Sidebar } from "components";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { COMPLETED, FILE_TYPES, IN_PROGRESS, IN_QUEUE, SUMMARY_STATUS } from "common/constants";
import "pages/summary/_summary.scss";

function Summary() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {summaryData, loading} = useSelector(state => state.general);

  const handleGenerateSummary = async () => {
    try {
      dispatch(generalExtraActions.transcriptionSummaryCreate(id)).then((response) => {
        if (response?.type === "general/transcriptionSummaryCreate/fulfilled") {
          dispatch(generalExtraActions.transcriptionSummaryList());
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportSummary = async () => {
    utils.generateSummaryPDF(summaryData);
  }


  useEffect(() => {
    if (id) {
      dispatch(generalExtraActions.transcriptionSummaryDetail(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(generalExtraActions.transcriptionSummaryList());
  }, []);

  const getSummaryItemsToRender = (data) => {
    if (!data) {
      return null;
    } else if (Array.isArray(data)) {
      return data.map((item) => {
        return getSummaryItemsToRender(item)
      })
    } else if (typeof data === 'object') {
      return Object.keys(data).map((key, index) => (
        <div key={index} className="summary-sub-text">
          <span className='summary-head'>{utils.snakeToTitleCase(key)}:</span>
          {getSummaryItemsToRender(data[key])}
        </div>
      ));
    } else {
      return <p className="summary-sub-text">{data}</p>
    }
  }

  return (
    <div className="summary">
      {loading && <Loader/>}
      <Sidebar forSummary={true}/>
      <div className="content">
        {id ? <div className="summary-detail">
          <div className="summary-section">
            <div className="summary-actions">
              <h1>
                Summary {id && summaryData?.summary_status !== undefined && `(${SUMMARY_STATUS[summaryData?.summary_status]})`}
              </h1>
              <div className="actions">
                {
                  id && summaryData?.summary_status !== IN_PROGRESS && summaryData?.summary_status !== IN_QUEUE &&
                  <a
                    onClick={handleGenerateSummary}>{summaryData?.summary_status === COMPLETED ? "Re-generate" : "Generate"}</a>
                }
              </div>
            </div>
            <div className="summary-text">
              {summaryData?.summary_status === COMPLETED && <>
                <div className="summary-metadata">
                  <div><b>Title: </b>{summaryData?.title}</div>
                  <div><b>File Type: </b>{FILE_TYPES[summaryData?.file_type]}</div>
                  <div><b>Duration: </b>{summaryData?.audio_duration}</div>
                  <div><b>Speakers: </b>{(summaryData?.speakers?.map(speaker => speaker.name) || []).join(', ')}</div>
                </div>
                {summaryData?.summary && Object.entries(utils.parseSummaryData(summaryData?.summary))?.map(([key, value]) => {
                  return <div className="summary-content">
                    <span className='summary-head'>{utils.snakeToTitleCase(key)}:</span>
                    <div className="summary-sub-text">
                      {getSummaryItemsToRender(value)}
                    </div>
                  </div>
                  return <span><b>{utils.snakeToTitleCase(key)}: </b>{value}</span>
                })}
              </>
              }
            </div>

          </div>
          <div className="buttons">
            <Link to={`${routes.TRANSCRIPTIONS}/${id}`} aria-disabled={!id}>Transcribed Text</Link>
            <a onClick={handleExportSummary}
               aria-disabled={summaryData?.summary_status !== COMPLETED}
            >
              Export Summary
            </a>
          </div>
        </div> : <div className="no-summary">
          Select the file from sidebar to view Summary
        </div>
        }
      </div>
    </div>
  );
}

export default Summary;
